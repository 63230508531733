
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, PropSync, Watch } from 'vue-property-decorator';

@Component
export default class ColorPicker extends Vue{
  // @VModel('input') color!: string;
  @PropSync('color', { type: String }) syncColor!: string;
  @Prop() label?: string;


  @Watch('syncColor')
  colorWatcher(value:string) {
      // temporary fix while there is no way to disable the alpha channel in the colorpicker component: https://github.com/vuetifyjs/vuetify/issues/9590
      if (value.toString().match(/#[a-zA-Z0-9]{8}/)) {
        this.syncColor = value.substring(0, 7);
      }
    }
  menu = false;
  get swatchStyle() {
      return {
        backgroundColor: this.syncColor,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        borderRadius: this.menu ? '50%' : '4px',
        transition: 'border-radius 200ms ease-in-out'
      }
  }
  mounted() {

  }
}
