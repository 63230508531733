<template>
  <v-card class="elevation-12">
    <v-toolbar
      color="primary"
      dark
      flat
    >
      <v-toolbar-title>Hotel information</v-toolbar-title>
    </v-toolbar>

    <v-card-text>
      <v-form
        ref="infoForm"
        @submit.prevent="save"
      >
        <v-text-field
          v-model="name"
          label="Name"
          :rules="nameRules"
          required
        />

        <v-text-field
          v-model="codeName"
          label="Code name"
          :rules="codeNameRules"
          required
        />

        <v-text-field
          v-model="uuid"
          label="Hotel UUID"
          readonly
          @click="copyUUID()"
        />

        <v-checkbox
          v-model="cast_enabled"
          label="Enable cast support"
        />

        <v-checkbox
          v-model="airplay_enabled"
          label="Enable airplay support"
        />
        <v-checkbox
          v-model="ads_enabled"
          label="Enable Ads"
        />

        <v-checkbox
          v-model="reboot_devices"
          label="Restart KonekOS twice a week"
        />

        <v-col class="text-right">
          <v-btn
            type="submit"
            color="primary"
          >
            Save
          </v-btn>
        </v-col>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import store from '@/store';
import { bus } from "@/plugins/events";

export default {
  data: () => ({
    name: "",
    nameRules: [
      v => !!v || 'Name is required',
      v => v.length > 8 || 'Name is too short',
      v => v.length < 128 || 'Name is too long'
    ],

    codeName: "",
    codeNameRules: [
      v => !!v || 'Code name is required',
      v => v.length < 20 || 'Code name is too long'
    ],

    uuid: "",
    cast_enabled: false,
    airplay_enabled: false,
    reboot_devices: false,
    ads_enabled: false,
  }),

  computed: {
    ...mapGetters([
      'location',
      'locations'
    ])
  },

  watch: {
    location: function() {
      this.loadLocation();
    },
  },

  mounted() {
    this.loadLocation();
  },

  methods: {
    loadLocation() {
      this.name = this.location.name;
      this.codeName = this.location.code_name;
      this.uuid = this.location.uuid;
      this.cast_enabled = this.location.cast_enabled;
      this.airplay_enabled = this.location.airplay_enabled;
      this.reboot_devices = this.location.reboot_devices;
      this.ads_enabled = this.location.ads_enabled;
    },

    copyUUID() {
      navigator.clipboard.writeText(this.location.uuid).then(() => {
        bus.$emit("show-snack", {
          text: "The uuid was copied to your clipboard!",
          color: 'success'
        });
      }, () => {
        bus.$emit("show-snack", {
          text: "Couldn't copy the uuid to your clipboard",
          color: 'error'
        });
      });
    },

    async save() {
      if (this.$refs.infoForm.validate()) {
        try {
          await axios.patch(`/v1/locations/${this.location.uuid}`, {
            name: this.name,
            codeNAme: this.code_name,
            cast_enabled: this.cast_enabled,
            airplay_enabled: this.airplay_enabled,
            reboot_devices: this.reboot_devices,
            ads_enabled: this.ads_enabled,
          });

          bus.$emit("show-snack", {
            text: "Location info was updated!",
            color: 'success'
          });

          const response = await axios.get("/v1/locations");

          store.commit('setLocations', {
            locations: response.data.locations
          });
        } catch(err) {
          const error = err.response.data.message;

          bus.$emit("show-snack", {
            text: error,
            color: 'error'
          });
        }
      }
    }
  }
}
</script>

<style>

</style>
