<template>
  <v-card class="elevation-12">
    <v-toolbar 
      color="primary"
      dark
      flat
    >
      <v-toolbar-title>Wifi settings</v-toolbar-title>
    </v-toolbar>

    <v-card-text>
      <v-form 
        ref="form"
        @submit.prevent="save"
      >
        <v-text-field
          v-model="wifiSsid"
          label="Wifi SSID"
        />

        <v-text-field
          v-model="wifiPassword"
          label="Wifi Password"
        />

        <v-col class="text-right">
          <v-btn 
            type="submit"
            color="primary"
          >
            Save
          </v-btn>
        </v-col>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import store from '@/store';
import { bus } from "@/plugins/events";

export default {
  data: () => ({
    wifiSsid: "",
    wifiPassword: "",
  }),

  computed: {
    ...mapGetters([
      'location',
      'locations'
    ])
  },

  watch: {
    location: function() {
      this.loadLocation();
    },
  },

  mounted() {
    this.loadLocation();
  },

  methods: {
    loadLocation() {
      this.wifiSsid = this.location.wifi_ssid;
      this.wifiPassword = this.location.wifi_password;
    },

    async save() {
      if (this.$refs.form.validate()) {
        try {
          await axios.patch(`/v1/locations/${this.location.uuid}`, {
            wifi_ssid: this.wifiSsid,
            wifi_password: this.wifiPassword
          });

          bus.$emit("show-snack", {
            text: "Hotel's wifi was updated!",
            color: 'success'
          });

          const response = await axios.get("/v1/locations");

          store.commit('setLocations', {
            locations: response.data.locations
          });
        } catch(err) {
          const error = err.response.data.message;

          bus.$emit("show-snack", {
            text: error,
            color: 'error'
          });
        }
      }
    },
  }
}
</script>

<style>

</style>