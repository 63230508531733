<template>
  <v-card class="elevation-12">
    <v-toolbar 
      color="primary"
      dark
      flat
    >
      <v-toolbar-title>Food settings</v-toolbar-title>
    </v-toolbar>

    <v-card-text>
      <v-form 
        ref="form"
        @submit.prevent="save"
      >
        <v-select
          v-model="restoproxyLocation"
          :items="locations"
          item-text="name"
          item-value="uuid"
          label="Redirect the food section from another location"
          clearable
          @click:clear="restoproxyLocation = null"
        />
        <v-alert 
          dense
          text
          outlined
          type="error"
          :value="!canActivate"
        >
          There needs to be at least one restaurant defined to activate this section !
        </v-alert>
        <v-checkbox
          v-model="eatDrinkEnabled"
          :disabled="!canActivate"
          
          label="Enable Eat or Drink section"
        />
        <v-checkbox
          v-model="foodBeverageEnabled"
          :disabled="!canActivate"
          
          label="Enable Food&Beverage section"
        />
        <v-checkbox
          v-model="proximRestoEnabled"
          :disabled="!canActivate"
          
          label="Enable Nearby restaurants"
        />

        <v-col class="text-right">
          <v-btn 
            type="submit"
            color="primary"
          >
            Save
          </v-btn>
        </v-col>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import store from '@/store';
import { bus } from "@/plugins/events";

export default {
  data: () => ({
    foodBeverageEnabled: true,
    eatDrinkEnabled: true,
    proximRestoEnabled: true,
    canActivate: false,
    restoproxyLocation: null,
  }),

  computed: {
    ...mapGetters([
      'location',
      'locations',
      ''
    ])
  },

  watch: {
    location: function() {
      this.loadLocation();
      this.getRestos();
    },
  },

  mounted() {
    this.loadLocation();
    this.getRestos()
  },

  methods: {
    loadLocation() {
      this.foodBeverageEnabled = this.location.food_drink_enabled;
      this.eatDrinkEnabled = this.location.eat_drink_enabled;
      this.proximRestoEnabled = this.location.proxim_resto_enabled;
      this.restoproxyLocation = this.location.resto_location_proxy;
    },

    async getRestos() {
      try {
        let response;
        if (this.restoproxyLocation == null) {
          response = await axios.get(`/v1/restaurants/list/${this.location.uuid}`);
        } else {
          response = await axios.get(`/v1/restaurants/list/${this.location.resto_location_proxy}`);
        }

        if (response.data.restaurants.length > 0) {

          console.log(response.data.restaurants)
          this.canActivate=true;
        }
        else {
          this.canActivate=false;
        }

      } catch(err) {
        const error = err.response.data.message;

        bus.$emit("show-snack", {
          text: error,
          color: 'error'
        });
      }
    },

    async save() {
      if (this.$refs.form.validate()) {

        try {
          await axios.patch(`/v1/locations/${this.location.uuid}`, {
            food_drink_enabled: this.foodBeverageEnabled,
            eat_drink_enabled: this.eatDrinkEnabled,
            proxim_resto_enabled: this.proximRestoEnabled,
            resto_location_proxy: (this.restoproxyLocation) ? this.restoproxyLocation : 'undefined',

          });

          bus.$emit("show-snack", {
            text: "Hotel's Food&Beverage settings were updated!",
            color: 'success'
          });

          const response = await axios.get("/v1/locations");

          store.commit('setLocations', {
            locations: response.data.locations
          });
        } catch(err) {
          const error = err.response.data.message;

          bus.$emit("show-snack", {
            text: error,
            color: 'error'
          });
        }
      }
    },
  }
}
</script>

<style>

</style>