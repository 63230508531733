<template>
  <v-container>
    <v-row
      justify="center"
    >
      <v-col
        cols="12"
        sm="8"
        md="4"
      >
        <InfoForm />
      </v-col>

      <v-col
        cols="12"
        sm="8"
        md="4"
      >
        <SettingsForm />
      </v-col>

      <v-col
        cols="12"
        sm="8"
        md="4"
      >
        <WeatherForm />
      </v-col>
    </v-row>

    <v-row
      justify="center"
    >
      <v-col
        cols="12"
        sm="8"
        md="4"
      >
        <WifiForm />
      </v-col>
      <v-col
        cols="12"
        sm="8"
        md="4"
      >
        <UnifiPortalForm />
      </v-col>

      <v-col
        cols="12"
        sm="8"
        md="4"
      >
        <BackupWifiForm />
      </v-col>


    </v-row>

    <v-row
      justify="center"
    >
    <v-col
        cols="12"
        sm="8"
        md="4"
      >
        <ServicesForm />
      </v-col>
      <v-col
        cols="12"
        sm="8"
        md="4"
      >
        <FoodBeverageForm />
      </v-col>

      <v-col
        cols="12"
        sm="8"
        md="4"
      >
        <WelcomeForm />
      </v-col>


    </v-row>
    <v-row
    >
      <v-col
        cols="12"
        sm="8"
        md="4"
      >
        <SatisfactionForm />
      </v-col>
    </v-row>


    <v-row />
  </v-container>
</template>

<script>
import InfoForm from "@/components/forms/InfoForm.vue";
import ServicesForm from "@/components/forms/ServicesForm.vue";
import SettingsForm from "@/components/forms/SettingsForm.vue";
import WeatherForm from "@/components/forms/WeatherForm.vue";
import WelcomeForm from "@/components/forms/WelcomeForm.vue";
import WifiForm from "@/components/forms/WifiForm.vue";
import FoodBeverageForm from "@/components/forms/FoodBeverageForm.vue";
import BackupWifiForm from "@/components/forms/BackupWifiForm.vue";
import UnifiPortalForm from "@/components/forms/UnifiPortalForm.vue";

import SatisfactionForm from "@/components/forms/SatisfactionForm.vue";

export default {
  components: {
    InfoForm,
    ServicesForm,
    SettingsForm,
    WeatherForm,
    WelcomeForm,
    WifiForm,
    FoodBeverageForm,
    BackupWifiForm,
    SatisfactionForm,
    UnifiPortalForm,
  },

  data: () => ({

  }),
}
</script>

<style>

</style>
