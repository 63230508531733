<template>
  <v-card class="elevation-12">
    <v-toolbar
      color="primary"
      dark
      flat
    >
      <v-toolbar-title>Survey Interface</v-toolbar-title>
    </v-toolbar>

    <v-card-text>
      <v-form
        ref="satisfactionform"
        @submit.prevent="save"
      >
        <v-checkbox
          v-model="satisfaction_survey"
          label="Enable satisfaction survey"
        />

        <v-select
          v-model="survey_index"
          :items="survey_indexes"
          item-text="type"
          item-value="number"
          label="Survey index:"
          required
        />


        <v-col class="text-right">
          <v-btn
            type="submit"
            color="primary"
          >
            Save
          </v-btn>
        </v-col>
      </v-form>
    </v-card-text>
  </v-card>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import store from '@/store';
import { bus } from "@/plugins/events";

export default {
  data: () => ({
    enabled: true,
    proxyLocation: null,
    survey_index: 0,
    satisfaction_survey:true, 

    survey_indexes: [
      { number: 0, type: 'Button Top Right' },
      { number: 1, type: 'Button Bottom Left' },
      { number: 2, type: 'Button Bottom Right' }
    ],
  }),

  computed: {
    ...mapGetters([
      'location',
      'locations'
    ])
  },

  watch: {
    location: function() {
      this.loadLocation();
    },
  },

  mounted() {
    this.loadLocation();
  },

  methods: {
    loadLocation() {
      this.satisfaction_survey = this.location.satisfaction_survey;
      this.survey_index=this.location.survey_index;
    },

    async save() {
      if (this.$refs.satisfactionform.validate()) {
        try {
          await axios.patch(`/v1/locations/${this.location.uuid}`, {
            survey_index: this.survey_index,
            satisfaction_survey:this.satisfaction_survey
          });

          bus.$emit("show-snack", {
            text: "Hotel settings was updated!",
            color: 'success'
          });

          const response = await axios.get("/v1/locations");

          store.commit('setLocations', {
            locations: response.data.locations
          });
        } catch(err) {
          const error = err.response.data.message;

          bus.$emit("show-snack", {
            text: error,
            color: 'error'
          });
        }
      }
    },
  }
}
</script>

<style>

</style>