<template>
  <v-card class="elevation-12">
    <v-toolbar 
      color="primary"
      dark
      flat
    >
      <v-toolbar-title>Services settings</v-toolbar-title>
    </v-toolbar>

    <v-card-text>
      <v-form 
        ref="form"
        @submit.prevent="save"
      >
        <v-select
          v-model="proxyLocation"
          :items="locations"
          item-text="name"
          item-value="uuid"
          label="Redirect the services to another location"
          clearable
          required
          @click:clear="proxyLocation = null"
        />
        <v-checkbox
          v-model="enabled"
          label="Enable services"
        />

        <v-col class="text-right">
          <v-btn 
            type="submit"
            color="primary"
          >
            Save
          </v-btn>
        </v-col>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import store from '@/store';
import { bus } from "@/plugins/events";

export default {
  data: () => ({
    enabled: true,
    proxyLocation: null,
  }),

  computed: {
    ...mapGetters([
      'location',
      'locations'
    ])
  },

  watch: {
    location: function() {
      this.loadLocation();
    },
  },

  mounted() {
    this.loadLocation();
  },

  methods: {
    loadLocation() {
      this.proxyLocation = this.location.location_proxy;
      this.enabled = this.location.services_enabled;
    },

    async save() {
      if (this.$refs.form.validate()) {
        console.log(this.proxyLocation);

        try {
          await axios.patch(`/v1/locations/${this.location.uuid}`, {
            location_proxy: (this.proxyLocation) ? this.proxyLocation : 'undefined',
            services_enabled: this.enabled
          });

          bus.$emit("show-snack", {
            text: "Hotel's services settings were updated!",
            color: 'success'
          });

          const response = await axios.get("/v1/locations");

          store.commit('setLocations', {
            locations: response.data.locations
          });
        } catch(err) {
          const error = err.response.data.message;

          bus.$emit("show-snack", {
            text: error,
            color: 'error'
          });
        }
      }
    },
  }
}
</script>

<style>

</style>