<template>
  <v-card class="elevation-12">
    <v-toolbar
      color="primary"
      dark
      flat
    >
      <v-toolbar-title>Hotel settings</v-toolbar-title>
    </v-toolbar>

    <v-card-text>
      <v-form
        ref="settingsForm"
        @submit.prevent="save"
      >
        <v-select
          v-model="timezone"
          :items="timezones"
          item-text="label"
          item-value="tzCode"
          :rules="[v => !!v || 'Timezone is required']"
          label="Timezone"
          required
        />

        <v-select
          v-model="country"
          :items="countries"
          item-text="name"
          item-value="code"
          :rules="[v => !!v || 'Country is required']"
          label="Country"
          required
        />

        <v-select
          v-model="language"
          :items="languages"
          item-text="language"
          item-value="code"
          :rules="[v => !!v || 'Language is required']"
          label="Language"
          required
        />

        <v-text-field
          v-model="supportemail"
          label="Support email"
          required
        />

        <v-select
          v-model="location_type"
          :items="location_types"
          item-text="type"
          item-value="number"
          label="Location type:"
          required
        />

        <v-col class="text-right">
          <v-btn
            type="submit"
            color="primary"
          >
            Save
          </v-btn>
        </v-col>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import store from '@/store';
import { bus } from "@/plugins/events";
import { minimalTimezoneSet } from 'compact-timezone-list';
import countryList from 'country-list';
import languageList from 'language-list';

export default {
  data: () => ({
    timezone: "",
    timezones: minimalTimezoneSet,

    country: "",
    countries: countryList.getData(),

    language: "",
    languages: languageList().getData(),

    location_type: 0,

    location_types: [
      { number: 0, type: 'Hotel' },
      { number: 1, type: 'Nursing Home' }
    ],

    supportemail: "",
  }),

  computed: {
    ...mapGetters([
      'location',
      'locations'
    ])
  },

  watch: {
    location: function() {
      this.loadLocation();
    },
  },

  mounted() {
    this.loadLocation();
  },

  methods: {
    loadLocation() {
      this.timezone = this.location.timezone;
      this.language = this.location.locale.split("_")[0];
      this.country = this.location.locale.split("_")[1];
      this.supportemail = this.location.support_email;
      this.location_type = this.location.location_type;
    },

    async save() {
      if (this.$refs.settingsForm.validate()) {
        try {
          await axios.patch(`/v1/locations/${this.location.uuid}`, {
            timezone: this.timezone,
            locale: `${this.language.toLowerCase()}_${this.country.toUpperCase()}`,
            support_email: this.supportemail,
            location_type: this.location_type
          });

          bus.$emit("show-snack", {
            text: "Hotel settings was updated!",
            color: 'success'
          });

          const response = await axios.get("/v1/locations");

          store.commit('setLocations', {
            locations: response.data.locations
          });
        } catch(err) {
          const error = err.response.data.message;

          bus.$emit("show-snack", {
            text: error,
            color: 'error'
          });
        }
      }
    }
  }
}
</script>

<style>

</style>
