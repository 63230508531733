<template>
  <v-card class="elevation-12">
    <v-toolbar 
      color="primary"
      dark
      flat
    >
      <v-toolbar-title>Backup Wifi settings</v-toolbar-title>
    </v-toolbar>

    <v-card-text>
      <v-form 
        ref="form"
        @submit.prevent="save"
      >
        <v-checkbox 
          v-model="BackupWifiEnabled"
          label="Enable Backup Wifi connexion"
        />

        <v-text-field
          v-model="BackupWifiSsid"
          label="Wifi SSID"
        />

        <v-text-field
          v-model="BackupWifiPassword"
          label="Wifi Password"
        />

        <v-col class="text-right">
          <v-btn 
            type="submit"
            color="primary"
          >
            Save
          </v-btn>
        </v-col>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import store from '@/store';
import { bus } from "@/plugins/events";

export default {
  data: () => ({
    BackupWifiEnabled: true,
    BackupWifiSsid: "",
    BackupWifiPassword: "",
  }),

  computed: {
    ...mapGetters([
      'location',
      'locations'
    ])
  },

  watch: {
    location: function() {
      this.loadLocation();
    },
  },

  mounted() {
    this.loadLocation();
  },

  methods: {
    loadLocation() {
      this.BackupWifiEnabled = this.location.backupwifi_enabled;
      this.BackupWifiSsid = this.location.backupwifi_ssid;
      this.BackupWifiPassword = this.location.backupwifi_password;
    },

    async save() {
      if (this.$refs.form.validate()) {
        try {
          await axios.patch(`/v1/locations/${this.location.uuid}`, {
            backupwifi_enabled: this.BackupWifiEnabled,
            backupwifi_ssid: this.BackupWifiSsid,
            backupwifi_password: this.BackupWifiPassword
          });

          bus.$emit("show-snack", {
            text: "Hotel's backup wifi was updated!",
            color: 'success'
          });

          const response = await axios.get("/v1/locations");

          store.commit('setLocations', {
            locations: response.data.locations
          });
        } catch(err) {
          const error = err.response.data.message;

          bus.$emit("show-snack", {
            text: error,
            color: 'error'
          });
        }
      }
    },
  }
}
</script>

<style>

</style>