<template>
  <v-card class="elevation-12">
    <v-toolbar
      color="primary"
      dark
      flat
    >
      <v-toolbar-title>Welcome screen settings</v-toolbar-title>
    </v-toolbar>

    <v-card-text>
      <v-form
        ref="form"
        @submit.prevent="save"
      >
        <v-select
          v-model="bannerImage"
          :items="images"
          item-text="name"
          item-value="file_url"
          label="Image to use on the welcome screen"
          clearable
          required
          @click:clear="bannerImage = undefined; save()"
        />

        <v-checkbox
        label="Music Enabled"
        v-model="musicEnabled"
        ></v-checkbox>
        <v-radio-group
          v-model="music_type"
          @change="change_music_type"
          column
        >
        <v-row>
          <v-radio
            hide-details
            class="shrink mr-2 mt-0"
          ></v-radio>
          <v-text-field
            :disabled="music_type !== 0"
            v-model="radioChannel"
            label="Radio Channel"
            required>
          </v-text-field>
        </v-row>

        <v-row>
          <v-radio
            hide-details
            class="shrink mr-2 mt-0"
          ></v-radio>
          <v-select
            :disabled="music_type !== 1"
            v-model="musicURL"
            :items="audios"
            item-text="name"
            item-value="file_url"
            label="Audio to use on the welcome screen"
            clearable
            required
            @click:clear="musicURL = undefined; save()"
          />
        </v-row>
        </v-radio-group>

        <v-col class="text-right">
          <v-btn
            type="submit"
            color="primary"
          >
            Save
          </v-btn>
        </v-col>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import store from '@/store';
import { bus } from "@/plugins/events";

export default {
  props: {
    location: Location
  },
  data: () => ({
    bannerImage: "",
    images: [],
    radioChannel: "",
    musicURL: "",
    musicEnabled: false,
    audios: [],
    music_type: 0,
  }),

  computed: {
    ...mapGetters([
      'location',
      'locations'
    ])
  },

  watch: {
    location: function() {
      this.loadLocation();
    },
  },

  mounted() {
    this.loadLocation();
    this.loadImages();
    this.loadAudios();
    if (this.musicURL!="") {
      this.music_type = 1;
    } else if (this.radioChannel!="") {
      this.music_type = 0;
    }
  },

  methods: {
    async loadImages() {
      axios.get("/v1/images").then((response) => {
        this.images = response.data.images;
        this.images = this.images.filter((image) => {
          if (image.category.toLowerCase() !== "banner") {
            return false;
          }

          if (image.location.uuid !== this.location.uuid) {
            return false;
          }

          return true;
        });
      }).catch(() => {
        bus.$emit('show-snack', {
          text: "Couldn't fetch images!",
          color: "error"
        });
      });
    },

    async loadAudios() {
      try {
        const response = await axios.get(`/v1/audios`);
        const audioFiles = response.data.audios; 

        this.audios = audioFiles.map((audio) => ({
          id: audio.id,
          name: audio.name,
          file_url: audio.file_url,
          file_path: audio.file_path,
          file_type: audio.file_type,
          location: audio.location
        }))
        .filter((audio) => audio.location != null && audio.location != this.location);
      } catch {
        bus.$emit('show-snack', {
          text: "Couldn't fetch audios!",
          color: "error"
        });
      }
    },

    loadLocation() {
      this.bannerImage = this.location.banner_url;
      this.musicEnabled = this.location.welcome_music_enabled;
      this.radioChannel = this.location.welcome_music_channel;
      this.musicURL = this.location.welcome_music_url;
    },

    async save() {
      if (this.$refs.form.validate()) {
        try {
          await axios.patch(`/v1/locations/${this.location.uuid}`, {
            banner_url: this.bannerImage,
            welcome_music_enabled: this.musicEnabled,
            welcome_music_channel: this.radioChannel,
            welcome_music_url: this.musicURL
          });

          bus.$emit("show-snack", {
            text: "Hotel's welcome screen was updated!",
            color: 'success'
          });

          const response = await axios.get("/v1/locations");

          store.commit('setLocations', {
            locations: response.data.locations
          });
        } catch(err) {
          const error = err.response.data.message;

          bus.$emit("show-snack", {
            text: error,
            color: 'error'
          });
        }
      }
    },

    change_music_type(){
      if (this.music_type == 0) {
        this.musicURL = "";
      } else if (this.music_type == 1) {
        this.radioChannel = "";
      }
    }
  }
}
</script>

<style>

</style>
